<template>
  <div style="background-color: #fafafa; padding-top: 20px">
    <div style="height: auto;padding-bottom: 20px; background-color: white">
      <div class="center" style="padding: 30px 0; overflow: hidden">
        <div
          style="width: 320px; height: 100%; float: left; margin-right: 20px"
        >
          <div
            style="
              height: 280px;
              border: 1px solid rgba(220, 220, 220, 0.5);
              margin-bottom: 20px;
            "
          >
            <img
              :src="imgIndexUrl"
              style="width: 320px; height: 280px"
              alt=""
            />
          </div>
          <div style="height: 45px; line-height: 45px">
            <div
                @click="changeImageIndex(false)"
              style="
                float: left;
                font-weight: bold;
                color: rgba(220, 220, 220, 1);
                cursor: pointer;
              "
            ><</div>

            <div

              style="
                float: left;
                display: flex;
                width: 290px;
                margin: 0 auto;
                height: 45px;
                margin-right: 5px;
                margin-left: 5px;
                justify-content: flex-start;
              "
            >
              <img
                :class="{ select_img: imgIndex == index }"
                :src="item.url"
                alt=""
                @click="chooseImage(index)"
                v-for="(item, index) in goodsImage"
                :key="index"
                style="
                  border: 1px solid rgba(220, 220, 220, 0.5);
                  width: 45px;
                  height: 45px;
                  margin-right: 10px;
                "
              />
            </div>
            <div
              @click="changeImageIndex(true)"
              style="
                float: right;
                font-weight: bold;
                color: rgba(220, 220, 220, 1);
                cursor: pointer;
              "
            >
              >
            </div>
          </div>
        </div>
        <div style="float: left; margin-right: 20px; width: 625px">
          <div style="height: 110px">
            <div style="width: 430px; float: left">
              <div>
                <p style="font-size: 20px; color: black">{{ goods.name }}</p>
              </div>
              <div style="margin-top: 10px">
                <p style="font-size: 14px">{{ goods.introduction }}</p>
              </div>
            </div>
            <div
              class="san_jiao"
              style="
                position: relative;
                text-align: center;
                color: rgb(255, 106, 0);
                width: 100px;
                float: right;
                height:auto;
                padding: 10px 0;
                border: 1px solid rgb(255, 106, 0);
                background-color: rgb(253, 242, 242);
                border-radius: 5px;
              "
            >
              <div>
                <p style="font-size: 22px; line-height: 28px">
                  <span>{{ goodsPatent.patentValue }}</span
                  >
                </p>
              </div>
              <div>
                <p style="font-size: 12px">高价值专利</p>
              </div>
              <div>
                <p style="font-size: 12px">中高评估</p>
              </div>
            </div>
          </div>
          <div
            style="
              height: 60px;
              background-color: rgb(250, 250, 250);
              padding: 0 10px;
              line-height: 60px;
            "
          >
            <div style="float: left">
              交易价格：<span style="color: #fc7c40; font-size: 20px"
                >￥{{ goodsStock.price }}</span
              >
            </div>
            <div style="float: right">
              交易方式：<span style="color: #fc7c40">{{ goods.modeName}}</span>
            </div>
          </div>
          <div style="margin-top: 20px; margin-bottom: 10px">
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="goodsPatent.an">申请号：{{ goodsPatent.an }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="goodsPatent.pa">权利人：{{ goodsPatent.pa }}</span>
            <span class="sqz_span" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="goodsPatent.type">专利类型：{{ goodsPatent.type }}</span>
          </div>
          <div
            style="
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 1px solid rgba(220, 220, 220, 1);
            "
          >
            <span>发明人：{{ goodsPatent.pin }}</span>
          </div>
          <div style=" display: flex;margin-top: 34px">
            <div
              style="
                cursor: pointer;
                margin-right: 20px;
                height: 35px;
                width: 180px;
                line-height: 35px;
                text-align: center;
                color: #fc7c40;
                border: 1px solid #fc7c40;
              "
              @click="jumpAction(1)"
            >
              立即购买
            </div>
            <div
              @click="returnRoom"
              style="
                cursor: pointer;
                height: 35px;
                width: 180px;
                line-height: 35px;
                text-align: center;
                background-color: #fc7c40;
                color: white;
              "
            >
              咨询商品
            </div>
          </div>
        </div>
        <div
          style="
          height: 340px;
            width: 210px;
            background-color: rgb(242, 242, 242);
            float: left;
            border-radius: 5px;
            padding: 20px 10px;
          "
        >
          <div>
            <span style="font-size: 14px; color: black">{{ shop.name }}</span>
          </div>
          <div style="height: 150px;display: flex;align-items: center;justify-content: center">
            <Avatar icon="ios-person" size="70" style="margin: 0 auto" :src="shop.imageUrl"/>
<!--            <img-->
<!--              :src="shop.enterpriseLicenseUrl"-->
<!--              style="height: 150px; width: 190px"-->
<!--              alt=""-->
<!--            />-->
          </div>
          <div style=";margin-top: 10px">
            <span
              v-show="shop.introduction"
              style="font-size: 14px; color: black"
              >店铺介绍:</span
            >
          </div>
          <div class="si_hang_sheng" style="margin-bottom: 15px">
        {{shop.introduction}}
          </div>
          <div
            @click="gotoShop()"
            style="
              font-size: 12px;
              background-color: #fc7c40;
              color: white;
              width: 80px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              margin: 0 auto;
              cursor: pointer;
            "
          >
            进入店铺
          </div>
        </div>

        <div></div>
      </div>
    </div>
    <div
      class="center bottom"
      style="
        background-color: white;
        margin-top: 20px;
        border: 1px solid rgb(220, 220, 220);
        margin-bottom: 20px;
      "
    >
      <span
        v-for="(item, index) in bottomList"
        :class="{ bottom_select: bottomIndex == index }"
        @click="changeBottomIndex(index)"
      >
        {{ item }}
      </span>
      <div
        v-show="true"
        style="
          min-height: 500px;
          border-top: 1px solid rgb(220, 220, 220);
          border-bottom: 1px solid rgb(220, 220, 220);
        "
        class="comments"
      >
        <div style="padding: 35px">
          <div
            v-show="bottomIndex == 0"
            v-html="goodsDetail.goodsDetail"
            class="html-box"
          ></div>
          <div
            v-show="bottomIndex == 1"
            v-html="goodsDetail.serviceDetail"
            class="html-box"
          ></div>
          <div
            v-show="bottomIndex == 2"
            style="width: 870px; margin-left: 100px;padding-top: 30px"
          >
            <div
              v-for="(item, index) in commentList"
              :key="index"
              style="border-bottom: 1px dashed #ddd"
            >
              <div
                style="
                  font-size: 18px;
                  line-height: 20px;
                  margin: 5px auto 10px;
                "
              >
                <span style="color: #ff6a00">{{ item.reviewUserName }}:</span>
                <span>{{ item.reviewContent }}</span>

              </div>
              <div
                style="
                  font-size: 16px;
                  line-height: 20px;
                  margin: 5px auto 10px;
                "
              >
                <span style="color: #ff6a00">{{item.shopName}}:</span>
                <span>{{ item.replyContent }}</span>
              </div>
            </div>
            <div v-if="commentList.length == 0">
              暂无咨询
            </div>
          </div>
          <div v-show="bottomIndex == 3">
            <Reportpage :report="report"></Reportpage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reportpage from "@/components/Report.vue";
import {
  getGoodDetail,
  getReport,
  getsimilar,
  getquote,
  sendComment,
  getComment,
  goodsRest
} from "@/plugins/api/Good";
import { getLocalToken } from "@/plugins/tools/storage";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
} from "@/plugins/tools/util";
import {errorToast} from "../plugins/tools/util";

export default {
  name: "GoodDetail",

  data() {
    return {
      commentsIndex: 1,
      imgIndex: 0,
      imgIndexUrl: "",
      bottomIndex: 0,
      bottomList: ["商品详情", "服务详情", "商品咨询", "价值评估报告"],
      valueDisabled: 2,
      goodId: 0,
      goods: "",
      goodsDetail: "",
      goodsImage: [],
      goodsPatent: "",
      goodsStock: "",
      shop: "",
      report: {
        labels: {},
        patent: {},
        evaluation: {},
        similarList: {},
        sqryzzlList: {},
      },
      textareaVal: "",
      commentList: [],
    };
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        let action = to.query.action || 0;
        if (JSON.stringify(to.query) !== "{}" && action == 0) {
          this.goodId = to.query.goodId;
          this.getGoodDetail(to.query.goodId);
          this.getReport(to.query.goodId);
        } else {
          this.$emit("changeIndex", action);
        }
      },
    },
  },
  components: {
    Reportpage,
  },
  methods: {
    chooseImage(index){
      this.imgIndex = index;
      this.imgIndexUrl = this.goodsImage[this.imgIndex].url
    },
    checkLogin() {
      if (!getLocalToken()) {
        this.$Modal.confirm({
          draggable: true,
          onOk: async () => {
            this.$router.push({ name: "login" });
          },
          render: (h) => {
            return h("div", [
              h(
                "div",
                {
                  style:
                    "text-align:center;margin-bottom:20px;padding-top:20px;vertical-align: middle;font-size: 16px;color: #17233d;",
                },
                "请先登录后再试"
              ),
            ]);
          },
        });
        return false;
      } else {
        return true;
      }
    },
    returnRoom: function () {
      const state = this.checkLogin();
      if (!state) {
        return;
      }

      this.$Modal.confirm({
        draggable: true,
        onOk: async () => {
          this.sendComment();
        },
        render: (h) => {
          return h("div", [
            h(
              "div",
              {
                style:
                  "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
              },
              "咨询内容"
            ),
            h("Input", {
              style: "width:100%;",
              props: {
                value: this.textareaVal,
                autofocus: true,
                placeholder: "请输入咨询内容",
                type: "textarea",
                autosize: true,
                maxlength:'300'
              },
              on: {
                input: (val) => {
                  this.textareaVal = val;
                },
              },
            }),
          ]);
        },
      });
    },
    //发送咨询
    sendComment() {
      sendComment({
        goodsId: this.goodId,
        reviewContent: this.textareaVal,
      }).then((res) => {
        if (res.code === 0){
          this.textareaVal = ""
          successToast("咨询成功")
        }
      });
    },
    //商品详情
    getGoodDetail(goodId) {
      getGoodDetail({ goodsId: goodId }).then((res) => {
        if (res.code === 0) {
          this.goods = res.result.goods;
          if(this.goods.mode == 'TRANSFER'){
            this.goods.modeName = "转让"
          }
          else if(this.goods.mode == 'LICENSE'){
            this.goods.modeName = "许可"
          }else{
            this.goods.monitorParams = "其他"
          }
          this.goodsPatent = res.result.goodsPatent;
          if(this.goodsPatent.patentValue != 0) {this.goodsPatent.patentValue.toFixed(2) + '分'}else{this.goodsPatent.patentValue='暂未评估'}
          this.goodsDetail = res.result.goodsDetail;
          this.goodsImage = res.result.goodsImage;
          this.imgIndexUrl = res.result.goods.mainImageUrl;
          this.goodsStock = res.result.goodsStock;
          this.shop = res.result.shop;
        }
      });
    },
    //评估报告
    getReport(goodId) {
      getReport({ goodsId: goodId }).then((res) => {
        if (res.code === 0) {
          if(res.result){
            this.report.labels = res.result.labels;
            this.report.patent = res.result.patent;
            this.report.evaluation = res.result.evaluation;
          }
        }
      });
      this.getComment(goodId);
      this.getsimilar(goodId);
      this.getquote(goodId);
    },
    //咨询留言列表
    getComment(goodId) {
      getComment({ goodsId: goodId }).then((res) => {
        if (res.code === 0) {
          this.commentList = res.result.list;
        }
      });
    },
    //相似度
    getsimilar(goodId) {
      getsimilar({ goodsId: goodId }).then((res) => {
        if (res.code === 0) {
          let arr = {};
          if (res.result.similarList) {
            res.result.similarList.forEach((item, index) => {
              arr[index] = item;
            });
            this.report.similarList = arr;
          }
        }
      });
    },
    // 引证
    getquote(goodId) {
      getquote({ goodsId: goodId }).then((res) => {
        if (res.code === 0) {
          if (res.result.sqryzzlList) {
            let arr = {};
            res.result.sqryzzlList.forEach((item, index) => {
              arr[index] = item;
            });
            this.report.sqryzzlList = arr;
          }
        }
      });
    },
    changeBottomIndex(index) {
      if (index == 2 || index == 3) {
        const state = this.checkLogin();
        if (!state) {
          return;
        }
      }
      this.bottomIndex = index;
    },
    chooseComments(index) {
      this.commentsIndex = index;
    },
    changeImageIndex(flag) {
      let max = this.goodsImage.length - 1;
      if (flag === true) {
        if (this.imgIndex < max) {
          this.imgIndex += 1;
        } else {
          this.imgIndex = 0;
        }
      }
      if (flag === false) {
        if (this.imgIndex !== 0) {
          this.imgIndex -= 1;
        } else {
          this.imgIndex = max;
        }
      }
      this.imgIndexUrl = this.goodsImage[this.imgIndex].url;
    },
    async jumpAction(index) {
      let json = await goodsRest({goodsId:this.goodId,num:1})
      if(json.code ==0){
        if(json.result == true){
          this.$router.push({
            name: 'good',
            query: {
              goodId: this.goodId,
              action: index
            }
          })
        }else{
          errorToast(json.message)
        }
      }else{
        errorToast(json.message)
      }

    },
    //进入店铺
    gotoShop() {
      this.$router.push({
        path: "OutsideStore",
        query: {
          shopId: this.shop.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .ivu-rate-star {
  margin-right: 2px;
}

.bottom_select {
  border-top: 1px solid rgb(255, 106, 0) !important;
  color: #ff6a00!important;
}

.comments_select {
  color: black;
}

.comments > span {
  display: inline-block;
  margin-left: 40px;
  line-height: 50px;
  font-size: 14px;
  cursor: pointer;
}

.bottom > span {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border: 1px solid rgb(220, 220, 220);
  width: 140px;
  height: 40px;
  line-height: 40px;
}

.sqz_span {
  display: inline-block;
  width: 180px;
  margin-right: 20px;
}

.san_jiao:after {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 10px solid rgb(255, 106, 0);
}

.si_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.san_jiao:before {
  content: "";
  position: absolute;
  left: -8px;
  top: 10px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 10px solid rgb(253, 242, 242);
  z-index: 100;
}

.select_img {
  border: 1px solid #fc7c40 !important;
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.html-box > img {
  display: block;
  margin: 0 auto;
  max-width: 100% !important;
  height: auto !important;
}

.html-box > p {
  max-width: 100%;
  word-break: break-all;
  font-size: 15px !important;
}

.html-box > span {
  max-width: 100%;
  word-break: break-all;
  font-size: 15px !important;
}

.html-box > table {
  width: 100%;
}
</style>
<style>
textarea.ivu-input {
  height: 120px !important;
}
</style>
